const errors = {
  "auth/invalid-email"              : "This is not a valid email",
  "auth/invalid-password"           : "This is not a valid password",
  "auth/invalid-phone-number"       : "This is not a valid phone number",
  "auth/phone-number-already-exists": "This phone number is already taken",
  "auth/uid-already-exists"         : "This UID is already taken",
  "auth/user-not-found"             : "Your email or password is incorrect",
  "auth/wrong-password"             : "Your email or password is incorrect",
};

export const mapError = code => errors[code] ? errors[code]: "An error has occurred";